import React, { Component } from 'react';
import { withRouter } from "react-router";
import {Helmet} from 'react-helmet'
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import { API } from '../helpers/API';
import BlogBBPSlider from '../blocks/BlogBBPSlider';
import BlogBBPLasts from '../blocks/BlogBBPLasts';
import CuradoriaLasts from '../blocks/CuradoriaLasts';
import TimeDeEspecialistas from '../blocks/TimeDeEspecialistas';
import Glossario from '../blocks/Glossario';

export class BlogBBP extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired
    };

    constructor( props ){

        super();
        this.state = {
            isLoaded : false,
            data : false
        }

    }

    async componentDidMount(){

        const data = await API.Common.getPageData( 'blogbbp' );
        this.setState({
            data: data,
            isLoaded: true
        })
    }

    render() {

        const { isLoaded, data } = this.state;
        if( !isLoaded ) return null;

        var _title = 'Blog BBP | BB Previdência';

        return (
            <React.Fragment>
                <div id="blogbbp" className="page blogbbp">
                    <Helmet>
                        <title>{_title}</title>
                        <meta property="og:title" content={_title} />
                    </Helmet>
                    <header className="cover">
                        <div>
                            <h2>{data.post_title}</h2>
                            <div>
                                {ReactHtmlParser(data.post_content)}
                            </div>
                        </div>
                    </header>
                    <BlogBBPSlider />
                    <BlogBBPLasts />
                    <CuradoriaLasts />
                </div>
                <style>
                    {data.cover && '#blogbbp>header{background-image:url('+data.cover+');}'}
                    {data.cover_mobile && '@media(max-width:540px){#blogbbp>header{background-image:url('+data.cover_mobile+');}}'}
                </style>
                <Glossario />
                <TimeDeEspecialistas />
            </React.Fragment>
        );
  
    }

}
  
export default withRouter( BlogBBP );